

div {
  &.clear {
    clear: both;
  }
  &.product-chooser {
    &.disabled div.product-chooser-item {
      zoom: 1;
      filter: alpha(opacity = 60);
      opacity: 0.6;
      cursor: default;
    }
    div.product-chooser-item {
      padding: 11px;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      border: 1px solid #efefef;
      margin-bottom: 10px;
//      margin-left: 10px;
//      margin-right: 10px;
      &:hover {
        background: rgba(236, 240, 241, .3);
        border: 4px solid #ccc;
        padding: 8px;
        filter: alpha(opacity = 100);
        opacity: 1;
      }
      &.selected,
      &.selected:hover{
        border: 4px solid #428bca;
        background: #efefef;
        padding: 8px;
        filter: alpha(opacity = 100);
        opacity: 1;
      }
      img {
        padding: 0;
      }
      span {
        &.title {
          display: block;
          margin: 10px 0 5px 0;
          font-weight: bold;
          font-size: 12px;
        }
        &.description {
          font-size: 12px;
        }
      }
      input {
        position: absolute;
        left: 0;
        top: 0;
        visibility: hidden;
      }
    }
  }
}